.about-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 100vh;
    background-color: rgba(31, 32, 41, 1);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    z-index: 10;
}

.about-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    display: block;
    overflow: hidden;
    height: 30px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    background-image: url("../../assets/icn/bs-cl.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
    cursor: pointer;
    z-index: 11;
}

.about-close:hover {
    transform: rotate(90deg);
}

.section-center {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.about-section img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    display: block;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.about-section p span {
    font-family: "Dancing Script", cursive;
    letter-spacing: 1px;
    color: #866baf;
    font-size: 25px;
    font-weight: 400;
}

.image-section {
    width: fit-content;
}

.credit-text-about {
    font-size: 10px;
    color: black;
    opacity: 0.7;
    text-align: start;
    left: 15px;
    bottom: 25px;
    position: relative;
}

.credit-text-about a {
    color: black;
    text-decoration: none;
}