/* Concert Text */
.concert-text {
    position: absolute;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
    bottom: 100%;
    cursor: pointer;
    z-index: 2;
    transform: translateY(-50%);
    transition: all 200ms linear;
}

.concert-section {
    position: fixed;
    top: 0;
    left: 0;
    padding: 100px 0;
    width: 100%;
    height: 100vh;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(31, 32, 41, 1);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    z-index: 10;
}

.concert-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-image: url("../../assets/icn/bs-cl.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
    cursor: pointer;
    z-index: 11;
    transition: transform 200ms linear;
}

.concert-close:hover {
    transform: rotate(90deg);
}

/* Header and Text for Concert Section */
.concert-section h3 {
    font-size: 44px;
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
}

.concert-section p {
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 1px;
    color: #fff;
}

.concert-section p span {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 700;
    color: #866baf;
}

/* Portfolio Photo Styling */
.portfolio-photo {
    width: 100%;
    transition: transform 0.3s ease;
}

.portfolio-photo:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

/* Portfolio Button Styling */
.portfolio-btn span:before {
    position: absolute;
    content: "";
    top: 10px;
    left: 50%;
    height: 45px;
    width: 30px;
    margin-left: -15px;
    z-index: -1;
    background-image: url("../../assets/icn/bt-d.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    opacity: 0.2;
    transition: all 200ms linear;
}

.portfolio-btn span:hover:before {
    opacity: 1;
    bottom: 5px;
}

/* Container for the Portfolio Section */
.container {
    max-width: 1224px;
    margin: auto;
    padding: 20px;
}

/* Photo gallery grid with 3 columns */
.photo-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    /* gap: 10px;  */
}

/* Each photo item */
.photo img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer;
    padding: 10px;
}

.photo:hover {
    cursor: pointer;
}

/* Hover effect */
.photo-concert img:hover {
    transform: scale(1.1);
}

/* Responsive adjustment for mobile */
@media screen and (max-width: 768px) {
    .photo-gallery2 {
        grid-template-columns: repeat(auto-fill, 1fr); /* 2 columns on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .photo-gallery2 {
        grid-template-columns: 1fr; /* 1 column on very small screens */
    }
}