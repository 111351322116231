.contact-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 100vh;
    background-color: rgba(31, 32, 41, 1);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    z-index: 10;
}

body.contact-on .contact-section {
    transform: translateX(0);
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
}

.contact-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    display: block;
    overflow: hidden;
    height: 30px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    background-image: url("../../assets/icn/bs-cl.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
    cursor: pointer;
    z-index: 11;
}

.contact-close:hover {
    transform: rotate(90deg);
}

.contact-section a {
    margin: 0 auto;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #fff;
    display: inline-block;
}

.contact-section .social a {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #866baf;
}

@media screen and (max-width: 580px) {
    .contact-section a {
        font-size: 17px;
    }

    .contact-section .social a {
        margin-left: 3px;
        margin-right: 3px;
        font-size: 13px;
        letter-spacing: 0;
    }

}

.section-center {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.social a .fa-icon {
    font-size: 2.5em;
    vertical-align: middle;
    color: #866baf;
}

.image-section {
    width: fit-content;
}

.credit-text {
    font-size: 10px;
    color: lightgray;
    opacity: 0.4;
    /*transform: rotate(90deg);*/
    text-align: start;
    left: 10px;
    bottom:15px;
    position: relative;
}

.credit-text a {
    color: white;
    font-size: 10px;
}