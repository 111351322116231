.hero-section {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
    height: 100vh;
    background-size: cover;
    /* background-position: center; */
    transform: scale(1) rotateX(0);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
    transform-origin: center top;
}

@media (min-width: 1024px) {
    .hero-section {
        background-position: 47% 27%;
    }

}

@media (max-width: 768px) {
    .hero-section {
        background-position: 47%;
    }
}

.hero-section h1 {
    color: #fff;
    font-size: 5vw;
    line-height: 1;
    font-weight: 900;
}

.hero-section .dancing {
    font-family: "Dancing Script", cursive;
    letter-spacing: 1px;
    color: #c3112e;
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
}

@media screen and (max-width: 580px) {
    .hero-section .dancing {
        font-size: 18px;
    }
}

.hero-section p {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1px;
}

.hero-section p span {
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-bottom: 35px;
}

.hero-section p span:before {
    position: absolute;
    content: "";
    bottom: 10px;
    left: 50%;
    height: 30px;
    width: 30px;
    margin-left: -15px;
    z-index: -1;
    background-image: url("../../assets/icn/bt-d.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    opacity: 0.2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.hero-section .arrow {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.hero-section .arrow:before {
    position: absolute;
    content: "";
    top: 20px;
    left: 10%;
    transform: translateX(-50%) rotate(90deg);
    height: 30px;
    width: 30px;
    background-image: url("../../assets/icn/bt-d.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    opacity: 0.2;
    transition: all 200ms linear;
}

.hero-section .arrow:hover:before {
    opacity: 1;
}

.fixed-bottom .arrow-bottom {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
}

.fixed-bottom .arrow-bottom:before {
    position: absolute;
    content: "";
    top: 20px;
    left: 10%;
    transform: translateX(-50%) rotate(90deg);
    height: 30px;
    width: 30px;
    background-image: url("../../assets/icn/bt-d.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    opacity: 0.2;
    transition: all 200ms linear;
}

.fixed-bottom .arrow-bottom:hover:before {
    opacity: 1;
}

.hero-section p span:hover:before {
    opacity: 1;
    bottom: 5px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 40px 0;
}

.fixed-bottom p {
    display: flex;
    justify-content: center;
    align-content: center;
}

.about-text,
.contact-text {
    position: absolute;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
    cursor: pointer;
    z-index: 2;
    transition: all 200ms linear;
}

.about-text {
    top: 50%;
    left: 40px;
    writing-mode: vertical-lr;
    transform: translateY(-50%);
}

.contact-text {
    top: 50%;
    right: 40px;
    writing-mode: vertical-lr;
    transform: translateY(-50%) rotate(180deg);
}

@media screen and (max-width: 580px) {
    .about-text {
        left: 15px;
    }

    .contact-text {
        right: 15px;
    }
}