.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1050;
}

.modal-content {
    background-color: #1f2029;
    color: white;
    border-radius: 8px;
}

.concert-close {
    position: absolute;
    top: 0px;
    width: 30px;
    height: 30px;
    background-image: url("../../assets/icn/bs-cl.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
    cursor: pointer;
    z-index: 11;
    transition: transform 200ms linear;
}

.concert-close:hover {
    transform: rotate(90deg);
}
