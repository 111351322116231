
a {
    cursor: pointer;
}

a:hover {
    text-decoration: none;
}

/* Overlay for images */
.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-overlay img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

/* Footer button positioning */
.fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 40px 0;
}
